import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Nav } from '../../components';
import { WorkSectionHeader } from '../../components';
import GlobalLayout from '../../layouts/GlobalLayout';

const P2P = () => {
  const imgStyleOutline = {
    boxShadow: '0px 4px 32px #00000015',
    maxWidth: '1280px',
    // marginLeft: '50vw',
    placeSelf: 'center',
    border: '1px solid black',
    marginTop: '3.33em',
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType='Product'
        workName='P2P Lending'
        what='Two-sided marketplace. Borrowers collateralize NFTs and/or other assets &amp; lenders compete via a price discovery mechanism for the loan.'
        who='Confidential'
        role='Wireframing'
        shoutout='Mariusz'
      />
      <ShowcaseContainer>
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/p2p/market.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/p2p/viewOffer.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/p2p/createOffer1.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/p2p/createOffer2.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/p2p/overview.png'
        />
      </ShowcaseContainer>
    </GlobalLayout>
  );
};

const ShowcaseContainer = styled.section`
  display: flex;
  flex-flow: column;
  padding: 3.33em 0;
  justify-content: center;
`;
export default P2P;
